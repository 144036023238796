import React, { memo, useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import Investigator from './Investigator';
import PropTypes from 'prop-types';

const Investigators = memo(({emp, reassign}) => {
  const [empData, setEmpData] = useState("");

  useEffect(() => {
    if(reassign) {
      setEmpData(emp.vendor_employees.sort((a, b) => b.selected - a.selected));
    } else {
      setEmpData(emp.vendor_employees);
    }
  },[emp]);

  return (
    <div>
      <Row>
      {empData && empData.length > 0 && empData.map((data)=>{
        return <Investigator employees={data} />
      })}
      </Row>
    </div>
  )
})

Investigators.propTypes = {
  emp:PropTypes.array.isRequired
}
export default Investigators;