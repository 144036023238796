import React, { memo, useEffect, useContext, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectAssignInvestigator from "./selectors";
import reducer from "./reducer";
import CommonNavbar from "../../components/CommonNavbar";
import path from "../../constants/pathConstants";
import Investigators from "./Investigators";
import { ApiDispatchContext } from "../../context/ApiContext";
import { updateEmployee } from "../EmploymentMasterPage/actions";
import { makeSelectEmployee } from "../EmploymentMasterPage/selectors";
import Filtericon from "./Filter-icon";
import InvestigatorButton from "./InvestigatorButton";
import InvestigatorFilter from "./InvestigatorFilter";
import { setFilterByState, setFilterByCity, selectInvestigator } from "./actions";
import { makeSelectAssignInvestigatorState, makeSelectAssignInvestigatorCity, makeSelectAssignInvestigatorId } from "./selectors";
import { getUrlParameter } from "../../constants/commonFunction";
import ErrorAlert from "../../components/ErrorAlert";
import FilterPopUp from "../../components/FilterPopUp";
import './index.css'
import Loader from "../../components/Loader";
import { setMultiCaseId } from "../OpenCases/actions";
import { decryptData } from "../../utils/crypto";
import { getEmpInfo } from "../../utils/getEmpInfo";
import { useNavigate } from "react-router-dom";
import { imageBaseUrl } from "../../utils/constants";

export function AssignInvestigator({ employeeUpdate, employees, filterByState, state, filterByCity, city, selectedEmployees, newselectInvestigator, setMultiCase }) {
  useInjectReducer({ key: "assignInvestigator", reducer });
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [open,setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const case_id = (getUrlParameter('case'));
  const reassign = Boolean(getUrlParameter('reassign'));
  const toggle = () => setModal(!modal);
  const openCases = Boolean(getUrlParameter('openCases'));

  const navigate = useNavigate();

  const employeeDetails = async(emp) => {
    setShowLoader(true)
    await api.employeeDetails(emp).then(data => {
      if (data.data && data.data.success && data.data.data) {
        setShowLoader(false);
       const tempempdata = decryptData(data.data.data);
        const groupedEmpInfo = getEmpInfo(tempempdata);
        const formattedData = {
          ...tempempdata,
          "vendor_employees": groupedEmpInfo
        }
        employeeUpdate(formattedData)
      } else if(!data.data.data){
        setShowLoader(false)
        employeeUpdate([])
      } else {
        setShowLoader(false)
        setModalTitle("Error Employee List");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setShowLoader(false)
      setModalTitle("Error Employee List");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
    })
  }

  const investBtnClick = () => {
    let cases = case_id.split(',');
    let casesList = cases.map(str => parseInt(str));
    let param = {
      "case_id": casesList,
      "assigned_to_investigator": selectedEmployees
    };
    api.assignInvestigator(param).then(data => {
      if (data.data && data.data.success) {
        if(reassign && reassign == true) {
          navigate(path.ASSIGN_INVESTIGATOR_SUCCESS + "?"+ btoa("title=Case re-assigned successfully&caseid=" + parseInt(case_id)));
        } else {
          navigate(path.ASSIGN_INVESTIGATOR_SUCCESS + "?" + btoa("title=You have Accepted the case&subtitle=You can view it under allotted cases"));
          setMultiCase([]);
        }
      } else {
        setModalTitle("Error Assign Employee");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error Employee List");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime');
      }      
      toggle();
    })
  }

  const filterToggle = () => {
    setOpen(!open)
  }

  const filteredEmployee = () => {
    let emp = {
      "filters": {
        "status": 'active'
      },
      "page": 0,
      "pageSize": 0,
    }
    if (reassign) {
      emp.caseId = parseInt(case_id);
    }
    if(city !== ""){
      emp.filters = {...emp.filters, 'city': city }
    }
    if(state !== "") {
      emp.filters = {...emp.filters, 'state': state }
    }
    employeeDetails(emp)
    setOpen(false);
  }

  const reset = ()=>{
    filterByState('');
    filterByCity('');
    setCityList([]);
    let emp = {
      "filters": {
        "status": 'active'
      },
      "page": 0,
      "pageSize": 0,
    }
    if (reassign) {
      emp.caseId = parseInt(case_id);
    }
    employeeDetails(emp)
    setOpen(true);
  }

  const getStatesList = useCallback(() => {
    api.getStateMaster().then(data => {
      if (data.data && data.data.success && data.data.data) {
        setStateList(data.data.data);
      }
      else {
        setModalTitle("Error");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
      }
      else {
        setModalBody('Please try after sometime');
      }
      toggle();
      }
      }).catch(err => {
        setModalTitle("Error");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
        setModalBody('Please try after sometime');
      }
        toggle();
      })
    }, []);
    
    const getCitiesList = useCallback(() => {
      if(state) {
        let payload ={
        "state": state
      }
      api.getCityMaster(payload).then(data => {
        if (data?.data?.data) {
          setCityList(data.data.data);
        }
        else {
          setModalTitle("Error");
          setModalBody(data.data.message);
        }
        }).catch(err => {
          setModalTitle("Error");
          if (err?.response?.data?.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    }
    }, [state]);

    useEffect(() => {
      getStatesList();
      newselectInvestigator([])
      employeeUpdate([])
    }, []);

    useEffect(() => {
      let emp = {
        "filters": {
          "status": 'active'
        },
        "page": 0,
        "pageSize": 0,
      }
      if (reassign) {
        emp.caseId = parseInt(case_id);
      }
      employeeDetails(emp)
    }, []);

    useEffect(() => {
      getCitiesList();
    }, [state])

  return (
    <div className="assign-investigator">
      <CommonNavbar title="Assign Investigator" backButton={openCases ? path.OPEN_CASE + '?'+ btoa('multiSelect=' + 'multiSelect') : null} search={true} />
      <div style={{marginTop:"10px"}}>
        <div className={reassign ? "assignInv-filter-main prev-assign" : "assignInv-filter-main"}>
          {reassign && <div className="prev-assigned">
            <img src={`${imageBaseUrl}/p-circle.svg`} alt="circle" />Previously Assigned
          </div>}
          <button data-testid="filterpopup" className="assignInv-filter-placeing" onClick={filterToggle}>
            <Filtericon />
          </button>
        </div>
        {employees && Array.isArray(employees.vendor_employees) ?
          <Investigators emp={employees} reassign={reassign} /> : !showLoader && <div className="No-investigator-found-parent"><p className="No-investigator-found">No Investigator at selected location</p></div> 
        }
      </div>
      <InvestigatorButton  datatestid="investigatorbtn" investigatorBtnClick={() => investBtnClick()} selectedInvestigator={selectedEmployees}/>
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle}>
        <InvestigatorFilter
          states={stateList}
          selectState={filterByState}
          valueOfState={state}
          valueOfCity={city}
          selectCity={filterByCity}
          cities={cityList}
          reset={reset}
          submit={filteredEmployee}
          data-testid="apply-btn"
        />
      </FilterPopUp>)}
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

AssignInvestigator.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employees: PropTypes.array,
  state: PropTypes.string,
  city: PropTypes.string,
  employeeUpdate: PropTypes.func,
  filterByState: PropTypes.func,
  filterByCity: PropTypes.func,
  setMultiCase: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  assignInvestigator: makeSelectAssignInvestigator(),
  employees: makeSelectEmployee(),
  state: makeSelectAssignInvestigatorState(),
  city: makeSelectAssignInvestigatorCity(),
  selectedEmployees: makeSelectAssignInvestigatorId()
});

export function mapDispatchToProps(dispatch) {
  return {
    employeeUpdate: (emp) => dispatch(updateEmployee(emp)),
    filterByState: (state) => dispatch(setFilterByState(state)),
    filterByCity: (city) => dispatch(setFilterByCity(city)),
    newselectInvestigator: (select) => dispatch(selectInvestigator(select)),
    setMultiCase: (caseid) => dispatch(setMultiCaseId (caseid))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(AssignInvestigator);