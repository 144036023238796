import React, {useEffect, useState} from 'react';
import './AssignInvestigatorSuccess.css';
import SuccessButton from '../../../components/SuccessButton';
import path from '../../../constants/pathConstants';
import { useNavigate } from "react-router-dom";
import { getUrlParameter } from '../../../constants/commonFunction';
import { imageBaseUrl } from '../../../utils/constants';

const AssignInvestigatorSuccess =() => {
  let [title,setTitle] = useState("");
  let [subtitle,setSubtitle] = useState("");
  const case_id = Number(getUrlParameter('caseid'));

  const navigate = useNavigate();

  useEffect(() => {
    setTitle(getUrlParameter('title'));
    setSubtitle(getUrlParameter('subtitle'));
  }, []);

  const HandleSuccessBack = () => {
    if(case_id) {
      navigate(path.REINVESTIGATE_CASES_DETAILS + '?' + btoa('case=' + case_id))
    } else {
      navigate(path.OPEN_CASE);
    }
  }
  
  return (
    <div className='AssignInvestigatorSuccess-wrapper'>
      <div data-testid="AssignInvestigatorSuccess" className='AssignInvestigatorSuccess'>
        <img className='AssignInvestigatorSuccess-tick' src={`${imageBaseUrl}/success.gif`} alt="success" />
        <span className='AssignInvestigatorSuccess-title'>{title}</span>
        <div className='AssignInvestigatorSuccess-subtitle'>{subtitle}</div>
      </div>
      <div className="AssignInvestigatorSuccess-btnDiv">
        <SuccessButton data-testid="save-button" onClick={() => HandleSuccessBack()} background={"#59CE8F"} color={"#FFFFFF"}>Proceed</SuccessButton>
      </div>
    </div>
  )
}

export default AssignInvestigatorSuccess