import PropTypes from 'prop-types'
import React, { memo } from 'react'
import CardComponent from '../../RejectedInvoices/CaseCard/CardComponent';

export const RejectedInvoiceCard = memo(({ selectCards }) => {
    return (
        <div>
            {selectCards && selectCards.map(item =>
                <CardComponent cardDetail={item} btn={false} />
            )}
        </div>
    )
})

RejectedInvoiceCard.propTypes = {}

export default RejectedInvoiceCard;