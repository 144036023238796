import React, { useState, useEffect, memo, useContext } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import './index.css';
import { compose } from 'redux';
import propTypes from 'prop-types';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import path from '../../../constants/pathConstants';
import { getEmployeeRole } from '../../../containers/App/selectors';
import { ApiDispatchContext } from '../../../context/ApiContext';
import { setAgeingReportDataAction } from '../actions';
import { makeAgeingData } from '../selectors';
import ErrorAlert from '../../../components/ErrorAlert';
import RadialBarChartWithLeaderLabels from '../../../components/SimpleRadialBarChart/index.js';
import { useNavigate } from 'react-router-dom';
const key = 'Mobile';
export function PerformanceReport({ employeeRole, setAgeingReportData, ageingData, isheaderRequired=true, viewReportHyperlink=true }) {
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const [selectedFilter, setSelectedFilter] = useState("")

  const navigate = useNavigate();

  useEffect(() => {
    api.getAgeingReportData({"customer_type": selectedFilter}).then(data => {
      if (data.data && data.data.success && data.data.data) {
       setAgeingReportData(data.data.data);
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, [selectedFilter]);

  const HandleFilterOptionClick = (name) => {
    if(name == "All") {
      setSelectedFilter("");
    } else {
      setSelectedFilter(name);
    }
  }

  return (
    <div className='main2-employee'>
      <div className='main3-employee'>
        {isheaderRequired ? <div className='header-performance'>Performance Report</div> : null}
        {viewReportHyperlink ? <div className='header2-performance'>
          <button className='performance-reportbutton' data-testid="PerformanceReport-btn3" onClick={()=>navigate(path.PERFORMANCE_REPORT)}>View reports</button>
        </div> : null}
      </div>
      <div className='main-performance'>
        <div className='main2-performance'>
          <div className='performance-heading'>Ageing Report</div>
          <div className='performance-div-btn'>
            <button className='performance-btn1' style={{ color: '#3A3A3A' }}>Self</button>
            {employeeRole == "vendor" && 
              <>
                <button className='performance-btn1' style={{ color: '#3A3A3A' }}> / </button>
                <button className='performance-btn2' style={{ color: '#2CB0F2' }}>Team</button>
              </>
            }
          </div>
        </div>
        <div className='performance-btn3-div'>
          <div key="all" className='performance-btn3-div1'>
            <button data-testid="PerformanceReport-btn1" className={selectedFilter == "" ? 'performance-btn3 selected' : 'performance-btn3'} onClick={() => HandleFilterOptionClick("All")}>All</button>
          </div>
          {ageingData && ageingData.filters && ageingData.filters.length > 0 && ageingData.filters.map((item, i) => {
            return (
              <div key={i} className='performance-btn3-div1'>
                <button data-testid="PerformanceReport-btn2" className={item == selectedFilter ? 'performance-btn3 selected' : 'performance-btn3'} onClick={() => HandleFilterOptionClick(item)}>{item}</button>
              </div>
            )
          })}
        </div>
        <div className='performance-width100'></div>
        {ageingData && ageingData.graphData ? <RadialBarChartWithLeaderLabels graphData={ageingData.graphData}/> : null}
        <div className='newPRbuttonstyle'>
          <div className='performance-lower-p percentageviewcss'>*Percentage out of TAT</div>
          <div className='employee-viewbtn viewreportbtnnew'>
            {/* <button className='radialprocesschart-lower-btn'  data-testid="PerformanceReport-btn4" onClick={()=>history.push(path.PERFORMANCE_REPORT)}>{'View Report'}<img style={{marginLeft:"7px"}} src={`${imageBaseUrl}/viewarrow.png`} /></button> */}
          </div>
        </div>
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}
PerformanceReport.propTypes = {
  employeeRole: propTypes.string,
  setAgeingReportData: propTypes.func,
  ageingData: propTypes.object
};

const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole(),
  ageingData: makeAgeingData()
});

export function mapDispatchToProps(dispatch) {
  return {
    setAgeingReportData: (data) => dispatch(setAgeingReportDataAction(data))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo,)(PerformanceReport);