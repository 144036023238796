import { connect } from 'react-redux';
import CardComponent from './CardComponent';
import { createStructuredSelector } from 'reselect';

export const CaseCard = (props) => {
    return (
        <div>
            {props.selectCards && props.selectCards.map((item, index) =>
            <CardComponent key={`openCasesCard-${index}`} showCaseType={props.showCaseType ? props.showCaseType : false} cardDetail={item} btn={true} showSelect={props.showSelect} handleMultiCaseSelect={props.handleMultiCaseSelect} />
        )}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CaseCard)