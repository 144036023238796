import React, { memo } from 'react';
import { CardComponent } from '../../AllottedCases/CaseCard/CardComponent';

const AllottedCaseCard = memo(({ selectCards }) => {
    return (
        <div>
            {selectCards && selectCards.map((item, index) =>
                <CardComponent key={`allottedCaseCard-${index}`} cardDetail={item} btn={false} />
            )}
        </div>
    )
})

AllottedCaseCard.propTypes = {}

export default AllottedCaseCard;