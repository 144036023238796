import React, { memo } from 'react';
import CardComponent from '../../OpenCases/CaseCard/CardComponent';

export const OpenCaseCard = memo(({ selectCards }) => {
    return (
        <div>
            {selectCards && selectCards.map((item, index) =>
                <CardComponent key={`openCaseCard-${index}`} cardDetail={item} btn={false} />
            )}
        </div>
    )
})

OpenCaseCard.propTypes = {}

export default OpenCaseCard;