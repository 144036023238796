import React, { useState, useContext, useEffect, memo } from 'react';
import PropTypes from "prop-types";
import EmploymentMasterNavbar from '../EmploymentMasterNavbar';
import EmployeeMasterCard from '../EmployeeMasterCard';
import { ApiDispatchContext } from "../../../context/ApiContext";
import ErrorAlert from '../../../components/ErrorAlert/Loadable';
import './SearchEmpPage.css';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import GenericSearch from '../../../components/GenericSearch';
import Loader from '../../../components/Loader';
import { decryptData } from '../../../utils/crypto';
import { getEmpInfo } from '../../../utils/getEmpInfo';
import { imageBaseUrl } from '../../../utils/constants';
export function SearchEmpPage() {
  const [suggestionOpen, setSuggestionOpen] = useState(false);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [searchedEmpData, setSearchedEmpData] = useState({});
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const toggle = () => setModal(!modal);

  useEffect(() => {
    setSearchValue("");
  }, []);

  const handleInputChange = (e) => {
    const inputVal = e.target.value;
    const sanitizedValue = inputVal.replace(/\\/g, ''); // Remove backslashes
    setSearchValue(sanitizedValue);
    setSearchedEmpData({});
    if(e.target.value.length >= 3) {
      setShowLoader(true);
      let payload = {
        keyword: e.target.value,
      }
      api.getEmpSuggestionList(payload).then(data => {
        if (data.data && data.data.success && data.data.data) {
          setShowLoader(false);
          if(data.data.data.length > 0) {
            if(data.data.data.length == 1 && data.data.data[0].value.length == 1 && data.data.data[0].value[0].value && e.target.value == data.data.data[0].value[0].display) {
              handleSuggestionClick(data.data.data[0].value[0].column, data.data.data[0].value[0].value, data.data.data[0].value[0].display)
            } else {
              setSuggestionOpen(true);
              setSuggestionList(data.data.data);
            }
          } else {
            setSuggestionOpen(false);
            setSuggestionList([]);
          }
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
          setShowLoader(false);
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
        setShowLoader(false);
      })
    } else {
      setSuggestionOpen(false);
    }
  }

  const handleSuggestionClick = (column_name, value, display_value) => {
    setShowLoader(true);
    setSuggestionOpen(false);
    let payload = {
      "filters": {},
      "search": {
        "column_name": column_name,
        "value": value
      }
    }
    api.employeeDetails(payload).then(data => {
      if (data.data && data.data.success && data.data.data) {
        setShowLoader(false);
        setSearchValue(display_value);
        const tempData = decryptData(data.data.data);
        const groupedEmpInfo = getEmpInfo(tempData);
        const formattedData = {
          ...tempData,
          "vendor_employees": groupedEmpInfo
        }
        setSearchedEmpData(formattedData);

      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
            setModalBody(data.data.message)
        }
        else{
            setModalBody('Please try after sometime');
        }
        toggle();
        setShowLoader(false);
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
      setShowLoader(false);
    })
  }

  return (
    <div data-testid="EmploymentSearch" className='employmentSearch'>
      <EmploymentMasterNavbar title="Search Employee" />
      <div className="search-emp-page">
        <GenericSearch
          searchValue={searchValue} 
          suggestionOpen={suggestionOpen} 
          handleInputChange={handleInputChange} 
          handleSuggestionClick={handleSuggestionClick} 
          suggestionList={suggestionList}
        />
        {searchValue && searchValue.toString().length >= 3 && suggestionOpen === false && showLoader == false ? 
          (searchedEmpData && searchedEmpData.vendor_employees && searchedEmpData.vendor_employees.length > 0 ? 
            <div className="search-emp-card-list">
              {Array.isArray(searchedEmpData.vendor_employees) &&
                searchedEmpData.vendor_employees.map((ele, index) => {
                  return (
                    <div className="emp-search-card">
                      <EmployeeMasterCard key={index} status={ele.status} srno={ele.srno} vendor_parent_name={searchedEmpData.vendor_parent_name} vendor_state={searchedEmpData.vendor_state}
                        vendor_city={searchedEmpData.vendor_city} investigator_name={ele.investigator_name} location={ele?.location} 
                        email={ele.email} mobile_number={ele.mobile_number} filename={ele.profileImage ? ele.profileImage.profile_image_name : ""} searchValue={searchValue} />
                    </div>
                  )
                })
              }
              <p className="endOfResult">End of result!</p>
            </div>
            :
            <div className="search-emp-empty-msg">
              <div className="search-empty-box">
                <img src={`${imageBaseUrl}/NoCaseIcon.png`} alt="NoCaseIcon" />
                <p className="search-emp-empty-text">No Employee found!</p>
              </div>
            </div>
          )
          : 
          (suggestionOpen === false&& showLoader == false ? <div className="search-emp-empty-msg">
            <div className="search-empty-box">
              <img src={`${imageBaseUrl}/SearchCase.png`} alt="SearchCaseIcon" />
              <p className="search-emp-empty-text">Looking for a specific employee?</p>
            </div>
          </div> : null)
        }
      </div>
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

SearchEmpPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo)(SearchEmpPage);