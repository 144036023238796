import React from 'react'
import './FilterButton.css'

function FilterButton(props) {

  const handleClick = () => {
    props.onFilterClick();
  };

  return (
    <div
      data-testid="filterbtn"
      className={props.active ? "filter-btn-active" : "filter-btn"}
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
}

export default FilterButton;
