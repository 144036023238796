import React, { memo } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import path from "../../constants/pathConstants";
import { useNavigate } from "react-router-dom";
import { imageBaseUrl } from "../../utils/constants";
import './index.css';
function ErrorAlert({ modal, toggle, modalBody }) {
  const navigate = useNavigate();
  return (
    <div>
      <Modal onClosed={() => (modalBody === "Case has been withdrawn" || modalBody === "One of the case has been withdrawn") && navigate(path.DASHBOARD)} className="erroralert" data-testid="modal" isOpen={modal} toggle={toggle} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:12, height:'-webkit-fill-available', }}>
        <div style={{ maxWidth: '500px', width: '100%' }}>
          <ModalHeader style={{fontSize:12}} data-testid="toggle-button" toggle={toggle}></ModalHeader>
          <ModalBody style={{fontSize:12}}>
            <div style={{textAlign:'center',margin:"0 0 40px 0"}}>
            {modalBody === "You have been Successfully Logged out" ? (<img src={`${imageBaseUrl}/success.gif`} alt="HDFC Ergo" style={{width:80, height:80}}/>) : (
              <img src={`${imageBaseUrl}/mingcute_alert-fill.png`} alt="HDFC Ergo" style={{width:70, height:70}}/>
            )}
            <div style={{marginTop:10}}>{modalBody}</div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

ErrorAlert.propTypes = {};

export default memo(ErrorAlert);