import React, { useEffect, useState } from 'react';
import ProfileImg from '../../../components/ProfileImg';
import StateAndCity from './StateAndCity/StateAndCity';
import './AddEmployee.css';
import { imageBaseUrl } from '../../../utils/constants';

const regexnumbers = /^[0-9]+$/;
const regexemail = `/[a-zA-Z][a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/`;

export function AddEmployee({ 
    index=0,
    onProfilePicHandler=() => {},
    removeAnother=() => {},
    vendorParentName="",
    vendorState="",
    vendorCity="",
    investigatorDetails=() => {},
    checkStateAndCityField=() => {},
}) {
    const [investigatorName, setInvestigatorName] = useState("")
    const [investigatorEmail, setinvestigatorEmail] = useState("");
    const [investigatorMobile, setinvestigatorMobile] = useState("");
    const [serviceLocation, setServiceLocation] = useState([]);
    const [stateFieldsCount, setStateFieldsCount] = useState([0]);
    const [stateCityArr, setStateCityArr] = useState([]);
    const [alreadySelectedStates, setAlreadySelectedStates] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [investigatorData, setInvestigatorData] = useState([{
            investigatorName: "",
            email: "",
            mobileNumber: "",
            status: "active",
            location: [],
            profileImage: {
              profile_image_size: "",
              profile_image_path: "",
              profile_image_name: "",
            }
    }]);

    const addMoreStates = () => {
        const currentLength = stateFieldsCount.length;
        setStateFieldsCount([...stateFieldsCount, currentLength]);
        setStateCityArr([...stateCityArr, { state: "", cities: [] }]) // adding empty state & city stateCityArr state
        setDisabled(true);
        checkStateAndCityField(index, true);
    }

    const removeStates = (para, locations) => {
        const copyOfStateCityArr = [...stateCityArr];
        const copyOfInvestigatorData = [...investigatorData];
        const copyOfStateFieldsCount = [...stateFieldsCount];
        copyOfStateCityArr.splice(para, 1); // removing the deleted state& city field
        setStateCityArr(copyOfStateCityArr); // updating the stateCityArr state
        copyOfStateFieldsCount.splice(para, 1);
        setStateFieldsCount(copyOfStateFieldsCount);

        const copyOfServiceLocation = structuredClone(serviceLocation);
        const filteredLocations = copyOfServiceLocation.filter(location => {
            return !locations.some(deleteLocation =>
                deleteLocation.state === location.state && deleteLocation.city === location.city
            );
        });

        copyOfInvestigatorData[0].location = filteredLocations;
        setInvestigatorData(copyOfInvestigatorData);
        setServiceLocation(filteredLocations);
        const uniqueStates = [...new Set(filteredLocations.map(entry => entry.state))];
        setAlreadySelectedStates(uniqueStates);
        setDisabled(false);
    }

    const nameChangeHandler = (name) => {
        setInvestigatorName(name);
        const tempData = [...investigatorData];
        tempData[0].investigatorName = name;
        setInvestigatorData(tempData);
    }

    const emailChangeHandler = (email) => {
        const tempData = [...investigatorData];
        if (!email.match(regexemail)) {
            tempData[0].email = email;
            setinvestigatorEmail(email);
            setInvestigatorData(tempData);
          } else if (email === '') {
            tempData[0].email = email;
            setinvestigatorEmail(email);
            setInvestigatorData(tempData);
          }
    }

    const mobileChangeHandler = (mobile) => {
        const tempData = [...investigatorData];
        if (mobile.match(regexnumbers) && mobile.length <= 10) {
            tempData[0].mobileNumber = mobile;
            setinvestigatorMobile(mobile);
            setInvestigatorData(tempData);
        } else if (mobile === '') {
            tempData[0].mobileNumber = mobile;
            setinvestigatorMobile(mobile);
            setInvestigatorData(tempData);
        }
    }

    const getSelectedStateAndCity = (parameter, data, tempArray, position=0) => {
            if(parameter === "city") {
                const allLocations = [...serviceLocation, ...tempArray];
                const copyOfStateCityArr = [...stateCityArr];
                copyOfStateCityArr[position] = data; // updating the stateCityArr state with latest values
                setStateCityArr(copyOfStateCityArr);
                const tempData = [...investigatorData];
                tempData[0].location = allLocations;
                setInvestigatorData(tempData);

            // Function to check for uniqueness based on state and city
            const isUnique = (value, index, self) => {
                return self.findIndex(item =>
                    item?.state?.toUpperCase() === value?.state?.toUpperCase() && item?.city?.toUpperCase() === value?.city?.toUpperCase()
                ) === index;
            };

            // Filter out repeated objects
            const uniqueLocations = allLocations.filter(isUnique);
            setServiceLocation(uniqueLocations);
            tempData[0].location = uniqueLocations;
            setInvestigatorData(tempData);
            setDisabled(false);
            }
      };

    useEffect(() => {
        investigatorDetails(investigatorData, index)
    }, [investigatorData]);

    useEffect(() => {
        const onlyStates = stateCityArr?.map((item) => item?.state);
        setAlreadySelectedStates(onlyStates);

        stateCityArr?.forEach(item => {
            if(item?.cities?.length <=  0) {
                checkStateAndCityField(index, true);
            } else {
                checkStateAndCityField(index, false);
            } 
          });
    }, [stateCityArr])

    return (
        <div className='viewemployee-main' style={{ marginTop: 20 }}>
            {index !== 0 && <button className='closeIcon' onClick={() => removeAnother(index)} data-testid="closeIcon"><img src={`${imageBaseUrl}/close.png`} alt="close" /></button>}
            <ProfileImg onProfilePicHandler={onProfilePicHandler} />
            <div className="viewemployee-container" style={{ paddingLeft: 5 }}>
                    <div>
                        <div className='viewemployee-child editemployeemain'>
                            <span className='viewemployee-text1 viewempset' >Vendor Parent Name</span>
                                <input
                                    placeholder="Vendor Parent Name"
                                    value={vendorParentName}
                                    type="text"
                                    style={{color:'#000', fontWeight:'500'}}
                                    className={"editEmployeeinput"}
                                    readOnly
                                />
                        </div>

                        <div className='viewemployee-child editemployeemain'>
                            <span className='viewemployee-text1 viewempset' >Vendor State</span>
                                <input
                                    placeholder="Vendor State"
                                    value={vendorState}
                                    type="text"
                                    style={{color:'#000', fontWeight:'500'}}
                                    className={"editEmployeeinput"}
                                    readOnly
                                />
                        </div>

                        <div className='viewemployee-child editemployeemain'>
                            <span className='viewemployee-text1 viewempset' >Vendor City</span>
                                <input
                                    placeholder="Vendor City"
                                    value={vendorCity}
                                    type="text"
                                    style={{color:'#000', fontWeight:'500'}}
                                    className={"editEmployeeinput"}
                                    readOnly
                                />
                        </div>

                        <div className='viewemployee-child editemployeemain'>
                            <span className='viewemployee-text1 viewempset' >Investigator Name</span>
                                <input
                                    placeholder=""
                                    value={investigatorName}
                                    type="text"
                                    style={{color:'#000', fontWeight:'500'}}
                                    className={"editEmployeeinput"}
                                    onChange={(e) => nameChangeHandler(e.target.value)}
                                />
                        </div>

                        {stateFieldsCount?.map((item, i) => (
                            <StateAndCity
                                currentLength={stateFieldsCount?.length}
                                key={item}
                                index={i}
                                onDataChange={getSelectedStateAndCity}
                                removeStates={removeStates}
                                actionType="add"
                                alreadySelectedStates={alreadySelectedStates}
                            />
                        ))}

                        {stateFieldsCount?.length > 6 ? null : (
                            <div className='viewemployee-child editemployeemain'>
                                <span className='viewemployee-text1 viewempset' ></span>
                                <label className="viewemployee-text2 viewempset">
                                    <button disabled={disabled} className={disabled ? "addMoreStatesBtnActive addMoreStatesBtnNotactive" : "addMoreStatesBtnActive"} onClick={addMoreStates}>
                                        + Add State
                                    </button>
                                </label>
                            </div>
                        )}

                        <div className='viewemployee-child editemployeemain'>
                            <span className='viewemployee-text1 viewempset' >Email ID</span>
                                <input
                                    value={investigatorEmail}
                                    type="email"
                                    style={{color:'#000', fontWeight:'500'}}
                                    className={"editEmployeeinput"}
                                    onChange={(e) => emailChangeHandler(e.target.value)}
                                />
                        </div>

                        <div className='viewemployee-child editemployeemain'>
                            <span className='viewemployee-text1 viewempset' >Mobile No.</span>
                                <input
                                    value={investigatorMobile}
                                    type="text"
                                    style={{color:'#000', fontWeight:'500'}}
                                    className={"editEmployeeinput"}
                                    onChange={(e) => mobileChangeHandler(e.target.value, 'Mobile No.')}
                                />
                        </div>

                        <div className='viewemployee-child editemployeemain'>
                                <span className='viewemployee-text1 viewempset' >Status</span>
                                <label className='viewemployee-text2 viewempset'>
                                    <input data-testid="status" type="radio" readOnly checked={'active'} style={{ position: 'relative', top: 2, accentColor: "#E94D51" }} />
                                    &nbsp;Active
                                </label>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default AddEmployee;