import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Input, Row, DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from 'reactstrap';
import './index.css';
import { FilterSearch } from '../../../constants/commonFunction';

export const InvestigatorFilter = (({ selectCity, valueOfCity, valueOfState, selectState, submit,reset, states, cities }) => {
  const [open, setOpen] = useState('');
  const [stateOpen, setStateOpen] = useState(false);
  const [filterState, setFilterState] = useState([...states]);
  const [stateSearchTerm, setStateSearchTerm] = useState(valueOfState);
  const [cityOpen, setCityOpen] = useState(false);
  const [filterCities, setFilterCities] = useState([...cities]);
  const [citySearchTerm, setCitySearchTerm] = useState(valueOfCity);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const cityFilter = (parameter) => {
    selectState(parameter)
    setStateSearchTerm(parameter);
    setCitySearchTerm("");
    if (parameter?.length === 2) {
      setFilterState(FilterSearch(parameter))
    } else if (parameter?.length < 2) {
      setFilterState([])
    }
  }

  const stateChangeHandler = useCallback((value) => {
    setStateSearchTerm(value?.toUpperCase());
    if(stateSearchTerm) {
      const filteredArray = states?.filter((data) => data?.toLowerCase().indexOf(value?.toLowerCase()) !== -1);
      setFilterState([...filteredArray])
    } else {
      setFilterState([...states])
    }
  }, [stateSearchTerm]);

  const cityChangeHandler = useCallback((value) => {
    setCitySearchTerm(value?.toUpperCase());
    if(citySearchTerm) {
      const filteredArray = cities?.filter((data) => data?.city_name?.toLowerCase().indexOf(value?.toLowerCase()) !== -1);
      setFilterCities([...filteredArray])
    } else {
      setFilterCities([...cities])
    }
  }, [citySearchTerm, cities]);

  const resetFilter = useCallback(() => {
    reset();
    setStateSearchTerm("");
    setCitySearchTerm("");
  }, [])

  const seletCityHandler = useCallback((cityName) => {
    selectCity(cityName);
    setCitySearchTerm(cityName)
  }, [])
  
  useEffect(() => {
    setFilterCities([...cities])
  }, [cities])

  return (
    <div className='investigator-filter'>
      <Row>
        <div className='header-text'>Filter</div>
      </Row>
      <div>
        <Accordion open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="2">Select State</AccordionHeader>
              <AccordionBody accordionId="2">
                <ButtonDropdown data-testid="InvestigatorFilter1" className='filterDropdown' toggle={() => { setStateOpen(!stateOpen) }} isOpen={stateOpen}>
                  <DropdownToggle className="" caret >
                    <Input
                        className='selectedValue'
                        type="text"
                        placeholder='Select State'
                        value={stateSearchTerm}
                        data-testid="LocationDropdown1"
                        onChange={(e) => stateChangeHandler(e.target.value)}
                    />
                  </DropdownToggle>
                  <DropdownMenu className="">
                    {filterState?.map(data => {
                      return <DropdownItem data-testid="InvestigatorFilter2" key={data} onClick={() => cityFilter(data)} className="form-control">{data}</DropdownItem>
                    })}
                  </DropdownMenu>
                </ButtonDropdown>
              </AccordionBody>
            </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="2">Select City</AccordionHeader>
              <AccordionBody accordionId="2">
                <ButtonDropdown data-testid="InvestigatorFilter1" className='filterDropdown' toggle={() => { setCityOpen(!cityOpen) }} isOpen={cityOpen}>
                  <DropdownToggle className="" caret >
                    <Input
                        className='selectedValue'
                        type="text"
                        placeholder='Select City'
                        value={citySearchTerm}
                        data-testid="LocationDropdown1"
                        onChange={(e) => cityChangeHandler(e.target.value)}
                    />
                  </DropdownToggle>
                  <DropdownMenu className="">
                    {filterCities?.map((data, i) => {
                      return <DropdownItem data-testid="InvestigatorFilter2" key={`${data?.city_name}-${i}`} onClick={() => seletCityHandler(data?.city_name)} className="form-control">{data?.city_name}</DropdownItem>
                    })}
                  </DropdownMenu>
                </ButtonDropdown>
              </AccordionBody>
            </AccordionItem>
        </Accordion>
      </div>
      <div className="btn-list-container">
        <div className='btn-list'>
          <Button data-testid="clear" className='reset-btn' onClick={resetFilter}>Reset</Button>
          <Button className='apply-btn' data-testid="apply-btn" onClick={() => submit()}>Apply</Button>
        </div>
      </div>
    </div>
  )
})

InvestigatorFilter.propTypes = {
  selectCity: PropTypes.func,
  valueOfCity: PropTypes.string,
  valueOfState: PropTypes.string,
  selectState: PropTypes.func
}

export default InvestigatorFilter;