import React from 'react'
import Icon from './Icon'
import {  Col, Button } from 'reactstrap'
import CardFields from './CardFields'
import { REINVESTIGATE_CASES_DETAILS } from '../../../constants/pathConstants'
import { useNavigate } from "react-router-dom";
import './card.css';

export function CardComponent({ cardDetail,btn,cardClass }) {
  const navigate = useNavigate();
  const navigateToDetails = (caseNum) => {
    navigate(REINVESTIGATE_CASES_DETAILS+'?'+btoa('case='+caseNum));
  }
  return (
    <div className={cardClass}>
      <div className='card-case'>
        <span className='card-case-lable'>{cardDetail[1].label} :<span style={{paddingLeft:"6px"}} className='card-case-value'>{cardDetail[0].value ? cardDetail[0].value : "-"}</span></span>
        <span className="icon-end">
        </span>
      </div>
      <div className='rein-caseCard-cardFields'>
      {cardDetail.length && cardDetail.map((item, index) => { if (index > 1) return <CardFields item={item} index={index} /> })}
        </div>
      {btn &&
            <Col  className='rein-details-view-btn'>
              <Button data-testid="btn-view" onClick={()=>navigateToDetails(cardDetail[1].value)}><Icon />  View</Button>
            </Col>}
    </div>
  )
}

export default CardComponent;