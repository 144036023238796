import { connect } from 'react-redux'
import CardComponent from './CardComponent'
import { createStructuredSelector } from 'reselect';

export const CaseCard = (props) => {
    return (
        <div>
            {props.selectCards && props.selectCards.map((item, index) =>
            <CardComponent key={`rejectedCasesCard-${index}`} cardDetail={item} btn={true} showCaseType={props.showCaseType ? props.showCaseType : false} />
        )}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CaseCard)