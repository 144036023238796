export const invoiceStatusLimitedLabelsMapping = {
    srNumber: "Sr. No.",
    invoice_no: "Invoice Number",
    net_amount: "Net Amount",
    gst_amount: "GST Amount",
    utrStatus: "Payment Status"
}

export const invoiceStatusLabelsMapping = {
    "srNumber": "Sr. No.",
    "invoice_no": "Invoice Number",
    "net_amount": "Net Amount",
    "gst_amount": "GST Amount",
    "utrStatus": "Payment Status",
    "Payee Name": "Payee Name",
    "NT_Cheque Date": "Payment Date",
    "Bank Name": "Payee Bank Name",
    "NT_Cheque No": "UTR NO",
    "Payment Mode": "Payment Mode",
    "Bank Branch Name": "Payee Bank Branch Name",
    "Invoice No": "HEGIC Invoice Number",
    "gross_amount": "Gross Amount",
    "net_payable": "Net Payable",
};

export const invoiceStatusLabelsSortingOrder = [
    "srNumber",
    "invoice_no",
    "net_amount",
    "gst_amount",
    "utrStatus",
    "Payee Name",
    "NT_Cheque Date",
    "Bank Name",
    "NT_Cheque No",
    "Payment Mode",
    "Bank Branch Name",
    "Invoice No",
    "gross_amount",
    "net_payable",
];