import React, { useState } from 'react';
import CustomFileViewer from '../../components/CustomFileViewer';
import { imageBaseUrl } from '../../utils/constants';
import "./index.css";

const ViewInvoices = ({
  fileName = "",
  fileSize = "",
  fileType = "",
  filePath = "",
}) => {
  const [viewFileModal, setViewFileModal] = useState(false);
  return (
    <div className='viewInvoices-container' onClick={()=> setViewFileModal(true)}>
        <button title={fileName} className="viewInvoices-btn">
          <img src={`${imageBaseUrl}/file.png`} alt="file" />
          <div className="viewInvoices-file-div">
            <span className="viewInvoices-name">{fileName?.length < 11 ? fileName : fileName?.slice(0, 10) + "..." + fileName?.split(".").pop()}</span>
            <span className="viewInvoices-size">{fileSize}</span>
          </div>
        </button>
        <CustomFileViewer fileType={fileType} filePath={filePath} setViewFileModal={setViewFileModal} viewFileModal={viewFileModal} />
    </div>
  )
}

export default ViewInvoices;