import React from 'react';
import { Col } from 'reactstrap';
import './card.css';
function CardFields(props) {
    return (
        <>
            <Col className='rein-details-card' xs="3" style={{width:"fit-content"}} key={props.index}>
                <div className='card-lable'>{props.item.label}</div>
                <div className='card-value'>{props.item.value ? props.item.value : "-"}</div>
            </Col>
        </>
    )
}

CardFields.propTypes = {}

export default CardFields
