import React, { useState } from "react";
import Icon from "../OpenCases/CaseCard/Icon";
import ViewInvoices from "./ViewInvoices";
import { imageBaseUrl } from "../../utils/constants";
import "./index.css";

const DetailsCard = ({ data, SrNumber }) => {
    const [showAll, setShowAll] = useState(false);
    const [showViewBtn, setShowViewBtn] = useState(true);
    const docs = data?.invoice_documents;

    const handleShowAll = () => {
        setShowAll(true);
        setShowViewBtn(false);
    }
    const handleShowless = () => {
        setShowAll(false);
        setShowViewBtn(true);
    }

  return (
    <div className="claim-maindiv detailsCard-border">
        {showAll && (
            <div className="claim-upbuttton-mainstyle">
                <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={handleShowless}><img className='claim-upbuttton-img' src={`${imageBaseUrl}/open-claim-up.png`} alt="claimup" /></button>
            </div>
        )}
        <div className="detailsCard-infoDiv">
            <div className="claim-map-data-breakDIV">
                <div className="map-data-text1">Sr. No.</div>
                <div className="map-data-text2">{SrNumber}</div>
            </div>
            <div className="claim-map-data-breakDIV">
                <div className="map-data-text1">Invoice Number</div>
                <div className="map-data-text2">{data?.invoice_no}</div>
            </div>
            <div className="claim-map-data-breakDIV" style={showAll ? {} : { textAlign: "end" }}>
                <div className="map-data-text1">Invoice Raised Date</div>
                <div className="map-data-text2">{data?.invoice_date}</div>
            </div>
        </div>
        {showAll && (
            <div style={{ marginTop: "20px" }}>
                <p className="detailsCard-Uploaded-title">Uploaded</p>
                <div className="documentListContainer">
                    {docs?.map((file, fileIndex) => (
                        <ViewInvoices
                            key={`${file.document_path[0]}-${fileIndex}`}
                            fileName={file.document_name}
                            fileSize={file.document_size}
                            fileType={file?.document_name?.substring(file?.document_name.lastIndexOf(".") + 1)}
                            filePath={file?.document_path[0]}
                        />
                    ))}
                </div>
            </div>
        )}
        {showViewBtn ? (
            <div className="detailsCard-viewBtn">
                <button className='buttonallotedcases' onClick={handleShowAll}><Icon />View Uploaded</button>
            </div>
            ) : (
            <div className="upBtnBottom">
                <button className='claim-upbuttton-style' onClick={handleShowless}><img className='claim-upbuttton-img' src={`${imageBaseUrl}/open-claim-up.png`} alt="claimup" /></button>
            </div>
            )}
    </div>
  )
}

export default DetailsCard;