import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import './card.css';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { makeSelectCaseSearchValue } from '../../SearchCase/selectors';
export function CardFields(props) {
    return (
        <>
             <Col className='card-mb open-details-card' style={{
                width:props.item.label === 'Last Rejection Reason' ? "75%": props.item.label === 'Sr. No.' ? "13%": props.item.label === 'Customer Name' ? '28%' :
                props.item.label === 'Policy No.' ? '34%' :"25%",
                margin:0 ,padding:'5px 5px'}} xs="3" key={props.index}>
                <div className='card-lable'>{props.item.label}</div>
                <div className={(props.searchValue && props.item.value == props.searchValue) ? 'card-value highlight' : 'card-value'}>{props.item.value ? props.item.value : "-"}</div>
            </Col>
        </>
    )
}

CardFields.propTypes = {
    searchValue: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
    searchValue: makeSelectCaseSearchValue()
});
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}
  
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);
  
export default compose( withConnect, memo)(CardFields);