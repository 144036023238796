import React from 'react'
import { connect } from 'react-redux'
import CardComponent from './CardComponent'
import { createStructuredSelector } from 'reselect';
import './card.css';

export const ReinvestigateCaseCard = (props) => {
    return (
        <div className='reinvestigatecardmargin'>
            {props.selectCards && props.selectCards.map(item =>
            <CardComponent cardClass={props.cardClass} cardDetail={item} btn={false} />
          )}
        </div>
    )
}

ReinvestigateCaseCard.propTypes = {};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReinvestigateCaseCard);