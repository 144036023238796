import { useState } from "react";
import Icon from '../OpenCases/CaseCard/Icon';
import lodash from "lodash";
import { invoiceStatusLimitedLabelsMapping, invoiceStatusLabelsMapping, invoiceStatusLabelsSortingOrder } from "../../utils/helper";
import { imageBaseUrl } from "../../utils/constants";

export const StatusCard = ({
    data = {},
    srNumber = 1,
}) => {
    const [showAll, setShowAll] = useState(false);
    const [showViewBtn, setShowViewBtn] = useState(true);
    const [labels, setLabels] = useState({ ...invoiceStatusLimitedLabelsMapping });
    
    const { utrdetails, ...dataWithoutUtrdetail } = data;
    const utrStatus = utrdetails?.length > 0 ? "Paid" : "Pending";
    const newData = utrdetails?.[0] ? { "srNumber": srNumber, ...dataWithoutUtrdetail, ...utrdetails?.[0], "utrStatus": "Paid", } : { "srNumber": srNumber, ...dataWithoutUtrdetail, "utrStatus": "Pending"};
    const allKeys = Object.keys(newData);

    const sortedKeys = allKeys.sort((a, b) => {
        const indexA = invoiceStatusLabelsSortingOrder.indexOf(a);
        const indexB = invoiceStatusLabelsSortingOrder.indexOf(b);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
    
        return indexA - indexB;
    });

    const handleShowAll = () => {
        setShowAll(true);
        setShowViewBtn(false);
        setLabels({ ...invoiceStatusLabelsMapping });
    }
    const handleShowless = () => {
        setShowAll(false);
        setShowViewBtn(true);
        setLabels({ ...invoiceStatusLimitedLabelsMapping });
    }
    
  return (
    <div 
        className={utrStatus === "Paid" ? "statusCard-mainDiv paid-status" 
            : utrStatus === "Pending" ? "statusCard-mainDiv pending-status"
            : "statusCard-mainDiv"
        }
    >
        {showAll && (
            <div className='claim-upbuttton-mainstyle'>
                <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={handleShowless}><img className='claim-upbuttton-img' src={`${imageBaseUrl}/open-claim-up.png`} alt="claimup" /></button>
            </div>
        )}

        <div className={showAll ? "statusCard-map-outerDiv" : "statusCard-map-outerDiv move-to-end"}>
            {Object.keys(newData)?.length && (
                <>
                    {Object.keys(newData)?.length && (
                        sortedKeys?.map((item, index) => {
                            let val = "-";
                            if (lodash.isEmpty(newData?.[item]?.toString())) {
                                val = "-";
                            } else if (typeof newData?.[item] === "object") {
                                val = "-";
                            } else if(item === "NT_Cheque Date") {
                                val = newData?.[item]?.split("T")[0];
                            } else {
                                val = newData?.[item];
                            }
                            return (labels?.[item]) ? (
                            <div className="statusCard-label-value" key={val + index}>
                                <div className="statusCard-label">{labels?.[item]}</div>
                                <div 
                                    className={val === "Paid" ? "statusCard-value paid-value" 
                                    : val === "Pending" ? "statusCard-value pending-value"
                                    : "statusCard-value"
                                }
                                >{val}</div>
                            </div>
                            ) : null;
                        })
                    )}
                </>
            )}
            {showViewBtn ? (
                utrStatus === "Paid" ?
                <div className='map-data-breakDIV claimbtn-main'>
                    <button data-testid="handleShowAllbtn-view" className='claimview-btn' onClick={handleShowAll}><Icon />View</button>
                </div>
                : null
            ) : (
                <div className="upBtnBottom">
                    <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={handleShowless}><img className='claim-upbuttton-img' src={`${imageBaseUrl}/open-claim-up.png`} alt="claimup" /></button>
                </div>
            )}
        </div>
    </div>
  )
}

export default StatusCard;